import { render, staticRenderFns } from "./CloseIcon.vue?vue&type=template&id=1a43cbde&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/pexea12/repos/vue-image-lightbox/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1a43cbde')) {
      api.createRecord('1a43cbde', component.options)
    } else {
      api.reload('1a43cbde', component.options)
    }
    module.hot.accept("./CloseIcon.vue?vue&type=template&id=1a43cbde&", function () {
      api.rerender('1a43cbde', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CloseIcon.vue"
export default component.exports